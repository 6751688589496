import { useEffect, useRef, useState } from "react";
import Map, { Source, Marker, Layer } from "react-map-gl";
import Supercluster, { AnyProps, PointFeature } from "supercluster";
import { useDispatch } from "react-redux";
import { Button, CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";

import useAsync from "hooks/useAsync";
import { PreviewLanguage, setPreviewLanguage } from "store/locations/slice";
import {
  DiscountProgramBrandLocation,
  DiscountProgramState,
  DiscountProgramBrand,
  PagedListOfDiscountProgramBrandLocation,
  DiscountProgramMall,
} from "services/borbalo-main.service";
import { adminDiscountProgramService } from "services/adminDiscountProgram";
import PreviewModal from "pages/ProvidersBrands/PreviewModal";
import { MAPBOX_TOKEN } from "services/apiUrl";
import { LocationPreview } from "pages/ProvidersBrands/Previews/LocationPreview";
import StateComponent from "components/StateComponent";
import MyTable from "components/MyTable";

import styles from "./DiscountProgramLocations.module.scss";

import "mapbox-gl/dist/mapbox-gl.css";

const DiscountProgramLocations = ({ title }: { title: string }) => {
  const mapRef = useRef<any>(null);
  const dispatch = useDispatch();
  const brandAsync = useAsync<DiscountProgramBrand, any>();
  const locationsAsync = useAsync<
    PagedListOfDiscountProgramBrandLocation,
    any
  >();
  const mallssAsync = useAsync<DiscountProgramMall[], any>();
  const [selectedLocation, setSelectedLocation] = useState<
    DiscountProgramBrandLocation | undefined
  >(undefined);
  const [selectedCluster, setSelectedCluster] = useState<
    DiscountProgramBrandLocation[]
  >([]);
  const [viewport, setViewport] = useState({
    latitude: 42.083967,
    longitude: 43.508241,
    zoom: 7,
  });

  const locations = locationsAsync.data?.entities;

  const сhangeLanguage = (language: PreviewLanguage) => {
    dispatch(setPreviewLanguage(language));
  };

  useEffect(() => {
    locationsAsync.run(adminDiscountProgramService.getAllLocations());
    mallssAsync.run(adminDiscountProgramService.getAllMalls());
    сhangeLanguage("en");
  }, []);

  useEffect(() => {
    if (selectedLocation?.brandId) {
      brandAsync.run(
        adminDiscountProgramService.getBrand(selectedLocation?.brandId),
      );
    } else {
      brandAsync.setData(new DiscountProgramBrand());
    }
  }, [selectedLocation?.brandId]);

  const createCircles = (malls: DiscountProgramMall[]) => {
    const createCircle = (
      latitude: number,
      longitude: number,
      radius: number,
    ) => {
      const points = 36;
      const earthRadius = 6371000; // Earth's radius in meters
      const circleCoords = [];

      for (let i = 0; i < points; i++) {
        const angle = (2 * Math.PI * i) / points;
        const dx = radius * Math.cos(angle);
        const dy = radius * Math.sin(angle);

        const lat = latitude + (dy / earthRadius) * (180 / Math.PI);
        const lon =
          longitude +
          ((dx / earthRadius) * (180 / Math.PI)) /
            Math.cos((latitude * Math.PI) / 180);

        circleCoords.push([lon, lat]);
      }

      return circleCoords;
    };

    return {
      type: "FeatureCollection",
      features: malls.map(
        ({ coordinates: { longitude = 0, latitude = 0 }, radius }) => ({
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [createCircle(latitude, longitude, 100)],
          },
        }),
      ),
    };
  };

  const supercluster = new Supercluster({
    radius: 40,
    maxZoom: 20,
  });

  const clusterPoints: PointFeature<AnyProps>[] = locations
    ? locations.map(location => ({
        type: "Feature",
        properties: { cluster: false, ...location },
        geometry: {
          type: "Point",
          coordinates: [
            location?.coordinates?.longitude || 0,
            location?.coordinates?.latitude || 0,
          ],
        },
      }))
    : [];

  supercluster.load(clusterPoints);

  const bounds = mapRef.current?.getMap().getBounds().toArray().flat();
  const clusters = bounds
    ? supercluster.getClusters(bounds, Math.floor(viewport.zoom))
    : [];

  const dataInfo =
    (selectedCluster &&
      selectedCluster?.map((item: DiscountProgramBrandLocation) => {
        return [
          item.locationNameEn,
          item.addressEn,
          item.cityEn,
          <StateComponent state={item.state} marginBottom={1} />,

          <Button onClick={() => setSelectedLocation(item)}>
            Show Preview
          </Button>,
        ];
      })) ??
    [];

  const tableData = [
    ["Location Name", "Address", "City", "State", ""],
    ...dataInfo,
  ];

  return (
    <>
      <Typography variant="h3" mb={1.6}>
        {title}
        {locationsAsync.isLoading && (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        )}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        Total {locationsAsync.data?.total}
      </Typography>
      <div className="column"></div>

      <div className={clsx(styles["map-wrapper"])}>
        <Map
          {...viewport}
          onMove={evt => setViewport(evt.viewState)}
          initialViewState={{
            latitude: 42.083967,
            longitude: 43.508241,
            zoom: 7,
          }}
          style={{ width: "100%", height: "100%" }}
          mapStyle="mapbox://styles/jgenti/clugsl1r300xs01p666mr5fxa"
          interactiveLayerIds={["unclustered-point"]}
          mapboxAccessToken={MAPBOX_TOKEN}
          ref={mapRef}
        >
          {clusters.map((cluster: any) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } =
              cluster.properties;
            const currentPixels =
              20 + pointCount / 2 > 20 ? 20 + pointCount / 2 : 20;

            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  longitude={longitude}
                  latitude={latitude}
                  onClick={() => {
                    const children = supercluster.getLeaves(
                      cluster.id,
                      Infinity,
                    );
                    setSelectedCluster(
                      children.map(child => {
                        const properties =
                          child.properties as Partial<DiscountProgramBrandLocation>;
                        return {
                          id: properties.id || "",
                          brandId: properties.brandId || "",
                          phone: properties.phone || "",
                          coordinates: properties.coordinates || {
                            latitude: 0,
                            longitude: 0,
                          },
                          ...properties,
                        } as DiscountProgramBrandLocation;
                      }),
                    );
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#00C6BE",
                      borderRadius: "50%",
                      width: `${currentPixels}px`,
                      height: `${currentPixels}px`,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }
            return (
              <Marker
                key={`location-${cluster.properties.id}`}
                longitude={longitude}
                latitude={latitude}
                onClick={() => setSelectedLocation(cluster.properties)}
              >
                <div
                  style={{
                    backgroundColor: "#00C6BE",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </Marker>
            );
          })}

          <Source
            id="malls-circles"
            type="geojson"
            data={createCircles(mallssAsync.data ?? [])}
          >
            <Layer
              id="malls-layer"
              type="fill"
              paint={{
                "fill-color": "red",
                "fill-opacity": 0.5,
              }}
            />
          </Source>
        </Map>

        <PreviewModal
          isOpen={!!selectedLocation}
          toggleModal={() => setSelectedLocation(undefined)}
        >
          {selectedLocation && (
            <>
              {selectedLocation && (
                <>
                  {selectedLocation.originalLocationId && (
                    <div className="column-align-center">
                      <Typography variant="h6" sx={{ color: "#00c6be", mb: 1 }}>
                        Active State
                      </Typography>
                      <LocationPreview
                        brand={brandAsync?.data || undefined}
                        language="en"
                        location={locations?.find(
                          item =>
                            item.id === selectedLocation.originalLocationId,
                        )}
                      />
                    </div>
                  )}
                  <div className="column-align-center">
                    {selectedLocation.state !==
                      DiscountProgramState?.Active && (
                      <Typography variant="h6" sx={{ color: "red", mb: 1 }}>
                        On Moderation
                      </Typography>
                    )}
                    <LocationPreview
                      brand={brandAsync?.data || undefined}
                      language="en"
                      location={selectedLocation}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </PreviewModal>

        <PreviewModal
          isOpen={!!selectedCluster?.length}
          toggleModal={() => setSelectedCluster([])}
        >
          {!!selectedCluster?.length && (
            <div className={clsx(styles["clusters-wrapper"])}>
              <h3>Locations in this cluster:</h3>
              <MyTable tableData={tableData} />
            </div>
          )}
        </PreviewModal>
      </div>
    </>
  );
};

export default DiscountProgramLocations;
