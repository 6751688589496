import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import uniqueBy from "lodash.uniqby";

import useAsync from "hooks/useAsync";
import MyTable from "components/MyTable";
import {
  ApiError,
  InvoicesOfCompany,
  PagedListOfInvoicesOfCompany,
} from "services/borbalo-main.service";
import { businessInvoicesService } from "services/businessInvoicesService";
import { useSearch } from "components/SearchComponent/useSearch";
import { formatedCompanyName } from "pages/BusinessInvoicesByMonthProviders";

const BusinessInvoicesByMonthProviders = ({ title }: { title: string }) => {
  const navigate = useNavigate();
  const { search, SearchComponent } = useSearch();
  const { data, isLoading, run } = useAsync<
    PagedListOfInvoicesOfCompany,
    ApiError
  >();

  useEffect(() => {
    run(businessInvoicesService.groupedByCompany());
  }, []);

  const sortedFinesData = useMemo(() => {
    if (search?.length) {
      return uniqueBy(
        data?.entities,
        (item: InvoicesOfCompany) => item.company.companyId,
      )
        .filter(
          item =>
            item.company.companyName
              ?.toLowerCase()
              .includes(search.toLowerCase()),
        )
        .map(item => new InvoicesOfCompany(item));
    } else {
      return data?.entities;
    }
  }, [data?.entities, search]);

  const dataInfo =
    sortedFinesData?.map((item, index) => [
      index + 1,
      formatedCompanyName(item.company),
      item.company.companyEmail,
      item.company.additionalCorrectionInNextMonthInvoice,
    ]) ?? [];

  const tableData = [
    [
      "",
      "Company Name",
      "Company Email",
      "Additional correction in next month invoice",
    ],
    ...dataInfo,
  ];

  const onPress = (index: number) => {
    const entity = data?.entities?.[index];
    if (entity?.company.companyId) {
      navigate(`/business-invoices/${entity.company.companyId}`);
    }
  };

  return (
    <>
      <Typography variant="h3" mb={1.6}>
        {title}
        {isLoading && (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        )}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        Total {data?.total}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        {SearchComponent}
      </Typography>
      <MyTable tableData={tableData} onPress={onPress} buttonText="Invoices" />
    </>
  );
};

export default BusinessInvoicesByMonthProviders;
