import { Button, CircularProgress, Typography } from "@mui/material";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import {
  DiscountProgramBrand,
  DiscountProgramBrandLocation,
  DiscountProgramState,
} from "services/borbalo-main.service";
import { adminDiscountProgramService } from "services/adminDiscountProgram";
import StateComponent from "components/StateComponent";
import PreviewModal from "pages/ProvidersBrands/PreviewModal";
import { CompanyPreview } from "pages/ProvidersBrands/Previews/CompanyPreview";
import { PreviewLanguage } from "store/locations/slice";

const BrandState = ({
  item,
  originalBrand,
  getAllBrands,
  locations,
  language,
}: {
  item: DiscountProgramBrand;
  originalBrand?: DiscountProgramBrand;
  getAllBrands: () => void;
  locations: DiscountProgramBrandLocation[];
  language: PreviewLanguage;
}) => {
  const changeStateAsync = useAsync<void, any>();
  const [loadingId, setLoadingId] = useState<string | undefined>();
  const [isOpenBrandState, setIsOpenBrandState] = useState<
    DiscountProgramBrand | undefined
  >(undefined);

  const stateChange = (brandId: string, state: DiscountProgramState) => {
    setLoadingId(brandId);
    changeStateAsync.run(
      adminDiscountProgramService.setStateOfBrand(brandId, state),
    );
  };

  useEffect(() => {
    if (changeStateAsync.isSuccess) {
      setIsOpenBrandState(undefined);
      setLoadingId(undefined);
      getAllBrands();
    }
  }, [changeStateAsync.isSuccess]);

  useEffect(() => {
    if (changeStateAsync.isError) {
      setLoadingId(undefined);
    }
  }, [changeStateAsync.isError]);

  const buttons = (
    <>
      <Button
        disabled={
          item.state === DiscountProgramState.Active || loadingId === item.id
        }
        onClick={() => stateChange(item.id, DiscountProgramState.Active)}
      >
        Activate
        <CircularProgress
          size={10}
          sx={{
            alignSelf: "center",
            ml: 3.8,
            opacity: Number(loadingId === item.id),
          }}
        />
      </Button>
      <Button
        disabled={
          item.state === DiscountProgramState.Deactivated ||
          loadingId === item.id
        }
        onClick={() => stateChange(item.id, DiscountProgramState.Deactivated)}
      >
        Deactivate
        <CircularProgress
          size={10}
          sx={{
            alignSelf: "center",
            ml: 1.6,
            opacity: Number(loadingId === item.id),
          }}
        />
      </Button>
    </>
  );

  return (
    <div className="column-align-center">
      <StateComponent state={item.state} marginBottom={1} />
      {buttons}
      <Button onClick={() => setIsOpenBrandState(item)}>Show Preview</Button>
      <PreviewModal
        isOpen={!!isOpenBrandState}
        toggleModal={() => setIsOpenBrandState(undefined)}
        buttons={buttons}
      >
        {isOpenBrandState && (
          <>
            {isOpenBrandState.originalBrandId && originalBrand && (
              <div className="column-align-center">
                <Typography variant="h6" sx={{ color: "#00c6be", mb: 1 }}>
                  Active State
                </Typography>
                <CompanyPreview
                  brandToShow={originalBrand}
                  locations={locations}
                  language={language}
                />
              </div>
            )}
            <div className="column-align-center">
              {isOpenBrandState.state !== DiscountProgramState.Active && (
                <Typography variant="h6" sx={{ color: "#f9b60a", mb: 1 }}>
                  On Moderation
                </Typography>
              )}
              <CompanyPreview
                brandToShow={isOpenBrandState}
                locations={locations}
                language={language}
              />
            </div>
          </>
        )}
      </PreviewModal>
    </div>
  );
};

export default BrandState;
