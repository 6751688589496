import { useEffect } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import moment from "moment";

import useModal from "hooks/useModal";
import useAsync from "hooks/useAsync";
import MyTable from "components/MyTable";
import {
  ApiError,
  PagedListOfAwardedSubscription,
} from "services/borbalo-main.service";
import { adminProfilesService } from "services/adminProfilesService";

import AddSubscriptionModal from "./AddSubscriptionModal";

const AwardedMonthsProviders = ({ title }: { title: string }) => {
  const addSubscription = useModal();
  const { data, isLoading, run } = useAsync<
    PagedListOfAwardedSubscription,
    ApiError
  >();

  useEffect(() => {
    run(adminProfilesService.getAwardedSubscriptions());
  }, []);

  const handleAddClick = () =>
    run(adminProfilesService.getAwardedSubscriptions());

  const dataInfo =
    data?.entities?.map(item => [
      item.userPhone,
      item.registration,
      item.monthCount,
      item.createdAt
        ? moment(item.createdAt).format("DD.MM.YYYY HH:mm:ss")
        : "",
      item.subscriptionEnd
        ? moment(item.subscriptionEnd).format("DD.MM.YYYY HH:mm:ss")
        : "",
      item.messageEn,
      item.messageGe,
    ]) ?? [];

  const tableData = [
    [
      "Phone Number",
      "Registration Number",
      "Month count",
      "Subscription add",
      "Subscription end",
      "Message En",
      "Message Ge",
    ],
    ...dataInfo,
  ];

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h3" mb={1.6}>
          {title}
          {isLoading && (
            <CircularProgress
              sx={{
                alignSelf: "center",
                marginLeft: 3.6,
              }}
            />
          )}
        </Typography>

        <Button onClick={addSubscription.handleOpenModal}>
          Add subscription
        </Button>
      </Box>

      <Typography variant="h4" mb={1.6}>
        Total {data?.total}
      </Typography>
      <MyTable tableData={tableData} buttonText="Invoices" />
      <AddSubscriptionModal
        isOpen={addSubscription.open}
        toggleModal={addSubscription.handleCloseModal}
        handleAddClick={handleAddClick}
      />
    </>
  );
};

export default AwardedMonthsProviders;
