import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const MyTable = ({
  tableData,
  onPress,
  onPressDisabled,
  buttonText,
  limitText,
  isButtonDisabled,
  groups,
  tableTemplate,
}: {
  tableData: (string | undefined | React.ReactNode)[][];
  onPress?: (index: number) => void;
  onPressDisabled?: (index: number) => boolean;
  buttonText?: string;
  limitText?: boolean;
  isButtonDisabled?: boolean;
  groups?: {
    [key: string]: { index: number; count: number; onPress: () => void };
  };
  tableTemplate?: string;
}) => {
  const classes = useStyles();

  const header = tableData[0];

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="my table">
        <TableHead>
          <TableRow>
            {header.map((headerItem, index) => (
              <TableCell key={index}>{headerItem}</TableCell>
            ))}
            {!!onPress && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.slice(1).map((row, index) => (
            <>
              {typeof row[4] === "string" &&
                groups?.[row[4]]?.index === index && (
                  <TableRow
                    sx={{
                      backgroundColor: "rgba(0, 198, 190, 0.1)",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      colSpan={3}
                      sx={{
                        fontSize: 20,
                      }}
                    >
                      {row[4]}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontSize: 20,
                      }}
                    >
                      {groups?.[row[4]]?.count +
                        " Location" +
                        (groups?.[row[4]]?.count > 1 ? "s" : "")}
                    </TableCell>
                    <TableCell component="th" scope="row" />
                    <TableCell component="th" scope="row">
                      <Button onClick={groups?.[row[4]]?.onPress}>
                        {"Change Rename"}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              <TableRow key={index}>
                {row.map((text, rowIndex) => (
                  <>
                    {typeof text === "string" ? (
                      <TableCell
                        key={text + rowIndex + index}
                        component="th"
                        scope="row"
                        sx={{
                          width: limitText && rowIndex === 1 ? "400px" : "auto",
                        }}
                      >
                        {limitText ? (
                          <div
                            style={{
                              maxHeight: 121,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 6,
                              maxWidth: 300,
                            }}
                          >
                            {text}
                          </div>
                        ) : (
                          text
                        )}
                      </TableCell>
                    ) : (
                      <TableCell
                        key={index + rowIndex}
                        component="th"
                        scope="row"
                        sx={{
                          width: limitText && rowIndex === 1 ? "600px" : "auto",
                        }}
                      >
                        {text}
                      </TableCell>
                    )}
                  </>
                ))}
                {!!onPress && (
                  <TableCell>
                    <Button
                      onClick={() => onPress(index)}
                      disabled={
                        onPressDisabled
                          ? onPressDisabled(index)
                          : isButtonDisabled
                      }
                    >
                      {buttonText ?? "Contacted"}
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MyTable;
