import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import { formatPrice } from "utils";

import useAsync from "hooks/useAsync";
import {
  ApiError,
  PagedListOfInvoicesOfCompany,
} from "services/borbalo-main.service";
import { businessInvoicesService } from "services/businessInvoicesService";
import MyTable from "components/MyTable";
import { formatedCompanyName } from "pages/BusinessInvoicesByMonthProviders";

import MarkInvoiceAsPaid from "./MarkInvoiceAsPaid";
import InvoiceSentIcon from "./InvoiceSentIcon";
import DownloadInvoice from "./DownloadInvoice";

const ProvidersInvoices = ({ title }: { title: string }) => {
  const { id } = useParams();
  const { data, isLoading, run } = useAsync<
    PagedListOfInvoicesOfCompany,
    ApiError
  >(false);

  const getBusinessInvoices = () => {
    if (id) {
      run(businessInvoicesService.groupedByCompany(undefined, id));
    }
  };

  useEffect(() => {
    getBusinessInvoices();
  }, [id]);

  const business = data?.entities?.[0];

  const dataInfo =
    business?.invoices?.map((item, index) => [
      index + 1,
      moment(item.month).format("DD.MM.YYYY"),
      `${item.monthSummary !== 0 ? `${formatPrice(item.monthSummary)}₾` : 0}`,
      <MarkInvoiceAsPaid
        invoice={item}
        companyId={id}
        requestFunction={getBusinessInvoices}
      />,
      <InvoiceSentIcon sent={item?.sent} />,
      <DownloadInvoice month={item?.month} companyId={id} />,
    ]) ?? [];

  const tableData = [
    ["", "Month", "Month Summary", "Paid", "Sent", ""],
    ...dataInfo,
  ];

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h3" mb={1.6}>
          {`${title} ${formatedCompanyName(business?.company)}`}
          {isLoading && (
            <CircularProgress
              sx={{
                alignSelf: "center",
                marginLeft: 3.6,
              }}
            />
          )}
        </Typography>
      </Box>
      <Typography variant="h4" mb={1.6}>
        Total {business?.invoices?.length}
      </Typography>
      <div className="column"></div>
      <MyTable tableData={tableData} />
    </>
  );
};

export default ProvidersInvoices;
