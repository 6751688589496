import { IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

import { useLoadInvoice } from "./useLoadInvoice";

const DownloadInvoice = ({
  month,
  companyId,
}: {
  month: Date | undefined;
  companyId: string | undefined;
}) => {
  const { isInvoiceLoading, loadInvoice } = useLoadInvoice();

  return (
    <IconButton
      aria-label="delete"
      disabled={isInvoiceLoading}
      color="primary"
      onClick={() => {
        companyId &&
          month &&
          loadInvoice(companyId, new Date(month.toString()));
      }}
      style={{ float: "inline-end" }}
    >
      <DownloadIcon />
    </IconButton>
  );
};

export default DownloadInvoice;
