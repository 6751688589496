import { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import moment from "moment";

import useAsync from "hooks/useAsync";
import { ApiError, IInvoiceInfo } from "services/borbalo-main.service";
import { businessInvoicesService } from "services/businessInvoicesService";

const MarkInvoiceAsPaid = ({
  invoice,
  companyId,
  requestFunction,
}: {
  invoice: IInvoiceInfo;
  companyId?: string | undefined;
  requestFunction?: () => void;
}) => {
  const changeStateAsync = useAsync<void, ApiError>();
  const [changedInvoices, setChangedInvoices] = useState<String[]>([]);

  useEffect(() => {
    if (changeStateAsync.isSuccess) {
      requestFunction?.();
    }
  }, [changeStateAsync.isSuccess]);

  const handleChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: IInvoiceInfo,
  ) => {
    const { checked } = event.target;

    if (checked) {
      changeStateAsync.run(
        businessInvoicesService.markInvoicesAsPaid(
          moment(item.month, "YYYY.MM.DD").year(),
          moment(item.month, "YYYY.MM.DD").month() + 1,
          companyId,
        ),
      );

      setChangedInvoices(prevState => [...prevState, item.number || ""]);
    }
  };

  const disabled =
    changeStateAsync.isLoading ||
    invoice?.paid ||
    (companyId ? changedInvoices.includes(invoice?.number) : invoice?.paid);

  return (
    <Switch
      defaultChecked={invoice?.paid}
      checked={invoice?.paid}
      disabled={disabled}
      onChange={e => handleChangeSwitch(e, invoice)}
    />
  );
};

export default MarkInvoiceAsPaid;
