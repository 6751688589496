import { Box, Button, CircularProgress, Typography } from "@mui/material";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import {
  Discount,
  DiscountProgramBrand,
  DiscountProgramState,
} from "services/borbalo-main.service";
import { adminDiscountProgramService } from "services/adminDiscountProgram";
import StateComponent from "components/StateComponent";
import PreviewModal from "pages/ProvidersBrands/PreviewModal";
import { DiscountRulesPreview } from "pages/ProvidersBrands/Previews/DiscountRulesPreview";
import { usePreloadedImage } from "pages/ProvidersBrands/Previews/usePreloadedImage";

const DiscountRulesState = ({
  brand,
  discounts,
  getAllDiscounts,
  isEn,
}: {
  brand: DiscountProgramBrand;
  discounts: Discount[];
  getAllDiscounts: () => void;
  isEn: boolean;
}) => {
  const changeStateAsync = useAsync<void, any>();
  const [loadingId, setLoadingId] = useState<string | undefined>();
  const [isOpenDiscountState, setIsOpenDiscountState] = useState<
    boolean | undefined
  >(false);

  const currentDiscounts = discounts.filter(
    item => item.brandId === brand.id || item.brandId === brand.originalBrandId,
  )!;

  const currentDiscountWithOriginalId = currentDiscounts.find(
    ({ originalDiscountId }) => !!originalDiscountId,
  );
  const currentDiscountWithoutOriginalId = currentDiscounts.find(
    ({ originalDiscountId }) => !originalDiscountId,
  );

  const currentDiscount =
    currentDiscountWithOriginalId ?? currentDiscountWithoutOriginalId;

  const {
    imageUrl: imageUrlWithOriginalId,
    imageFile: imageFileWithOriginalId,
  } = usePreloadedImage(currentDiscountWithOriginalId?.fullRulesFileUrl);
  const {
    imageUrl: imageUrlWithoutOriginalId,
    imageFile: imageFileWithoutOriginalId,
  } = usePreloadedImage(currentDiscountWithoutOriginalId?.fullRulesFileUrl);

  const imageUrl = imageUrlWithOriginalId ?? imageUrlWithoutOriginalId;
  const imageFile = imageFileWithOriginalId ?? imageFileWithoutOriginalId;

  const stateChange = (brandId: string, state: DiscountProgramState) => {
    if (currentDiscount) {
      setLoadingId(brandId);
      changeStateAsync.run(
        adminDiscountProgramService.setStateOfDiscount(
          currentDiscount.id,
          state,
        ),
      );
    }
  };

  useEffect(() => {
    if (changeStateAsync.isSuccess) {
      setLoadingId(undefined);
      setIsOpenDiscountState(undefined);
      getAllDiscounts();
    }
  }, [changeStateAsync.isSuccess]);

  useEffect(() => {
    if (changeStateAsync.isError) {
      setLoadingId(undefined);
    }
  }, [changeStateAsync.isError]);

  if (!currentDiscount) {
    return <>-</>;
  }

  const buttons = (
    <>
      <Button
        disabled={
          currentDiscount.state === DiscountProgramState.Active ||
          loadingId === currentDiscount.id
        }
        onClick={() =>
          stateChange(currentDiscount.id, DiscountProgramState.Active)
        }
      >
        Activate
        <CircularProgress
          size={10}
          sx={{
            alignSelf: "center",
            ml: 1.6,
            opacity: Number(loadingId === currentDiscount.id),
          }}
        />
      </Button>
      <Button
        disabled={
          currentDiscount.state === DiscountProgramState.Deactivated ||
          loadingId === currentDiscount.id
        }
        onClick={() =>
          stateChange(currentDiscount.id, DiscountProgramState.Deactivated)
        }
      >
        Deactivate
        <CircularProgress
          size={10}
          sx={{
            alignSelf: "center",
            ml: 1.6,
            opacity: Number(loadingId === currentDiscount.id),
          }}
        />
      </Button>
    </>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <StateComponent state={currentDiscount.state} marginBottom={1} />
      {buttons}
      <Button onClick={() => setIsOpenDiscountState(true)}>Show Preview</Button>
      <PreviewModal
        isOpen={!!isOpenDiscountState}
        toggleModal={() => setIsOpenDiscountState(undefined)}
        buttons={buttons}
      >
        {isOpenDiscountState && (
          <>
            {imageUrlWithOriginalId &&
              currentDiscountWithOriginalId &&
              currentDiscountWithoutOriginalId && (
                <div className="column-align-center">
                  <Typography variant="h6" sx={{ color: "#00c6be", mb: 1 }}>
                    Active State
                  </Typography>
                  <DiscountRulesPreview
                    discountRules={
                      (isEn
                        ? currentDiscountWithoutOriginalId.enSummary
                        : currentDiscountWithoutOriginalId.geSummary) ?? ""
                    }
                    name={(isEn ? brand.enTitle : brand.geTitle) ?? ""}
                    isEn={isEn}
                  />
                  {imageFileWithoutOriginalId && (
                    <a
                      href={imageUrlWithoutOriginalId}
                      target="_blank"
                      rel="noreferrer"
                      download={imageFileWithoutOriginalId?.name}
                    >
                      <Typography variant="h6" sx={{ color: "#00c6be", mb: 1 }}>
                        Download Full Rules File
                      </Typography>
                    </a>
                  )}
                  {!currentDiscountWithoutOriginalId.fullRulesFileUrl &&
                    currentDiscountWithoutOriginalId.fullRulesLink && (
                      <a
                        href={currentDiscountWithoutOriginalId.fullRulesLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Typography variant="h6" sx={{ color: "#00c6be" }}>
                          Open Full Rules Link
                        </Typography>
                      </a>
                    )}
                </div>
              )}
            <div className="column-align-center">
              {currentDiscount?.state !== DiscountProgramState.Active && (
                <Typography variant="h6" sx={{ color: "#f9b60a", mb: 1 }}>
                  On Moderation
                </Typography>
              )}
              <DiscountRulesPreview
                discountRules={
                  (isEn
                    ? currentDiscount.enSummary
                    : currentDiscount.geSummary) ?? ""
                }
                name={(isEn ? brand.enTitle : brand.geTitle) ?? ""}
                isEn={isEn}
              />
              {imageUrl && (
                <a
                  href={imageUrl}
                  target="_blank"
                  rel="noreferrer"
                  download={imageFile?.name}
                >
                  <Typography variant="h6" sx={{ color: "#00c6be", mb: 1 }}>
                    Download Full Rules File
                  </Typography>
                </a>
              )}
              {!currentDiscount.fullRulesFileUrl &&
                currentDiscount.fullRulesLink && (
                  <a
                    href={currentDiscount.fullRulesLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography variant="h6" sx={{ color: "#00c6be" }}>
                      Open Full Rules Link
                    </Typography>
                  </a>
                )}
            </div>
          </>
        )}
      </PreviewModal>
    </Box>
  );
};

export default DiscountRulesState;
