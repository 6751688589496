import { useEffect, useMemo } from "react";
import { CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import { formatPrice } from "utils";

import useAsync from "hooks/useAsync";
import {
  ApiError,
  ICompanyInfo,
  PagedListOfInvoicesOfMonth,
} from "services/borbalo-main.service";
import { businessInvoicesService } from "services/businessInvoicesService";
import MarkInvoiceAsPaid from "pages/ProvidersInvoices/MarkInvoiceAsPaid";
import InvoiceSentIcon from "pages/ProvidersInvoices/InvoiceSentIcon";
import DownloadInvoice from "pages/ProvidersInvoices/DownloadInvoice";

import GroupsTable from "./GroupsTable";

export const formatedCompanyName = (company?: ICompanyInfo) => {
  if (!company) {
    return "-";
  }

  return (
    company?.companyName +
    `${
      company?.deletedAt
        ? ` (Removed ${moment(company?.deletedAt).format(
            "DD.MM.YYYY HH:mm:ss",
          )})`
        : ""
    }`
  );
};

const BusinessInvoicesByMonthProviders = ({ title }: { title: string }) => {
  const { data, isLoading, run } = useAsync<
    PagedListOfInvoicesOfMonth,
    ApiError
  >();

  const requestFunction = () => {
    run(businessInvoicesService.groupedByMonth());
  };

  useEffect(() => {
    requestFunction();
  }, []);

  const tableData = [
    [
      "",
      "Company Name",
      "Company Email",
      "Number",
      "MonthSummary",
      "Paid",
      "Sent",
      "",
    ],
  ];

  const getGroupsAction = (month?: string) => {
    const currentMonth = data?.entities?.find(
      entitie => moment(entitie.month).format("MM.YYYY").toString() === month,
    );
    const notPaidInvoices = currentMonth?.companyInvoices?.find(
      item => !item.invoice.paid,
    )?.invoice;
    const invoice = notPaidInvoices
      ? notPaidInvoices
      : currentMonth?.companyInvoices?.[0]?.invoice;

    if (invoice) {
      return (
        <div
          style={{ width: "100%", textAlign: "start", marginTop: "-30px" }}
          className="flex justify-content-end"
        >
          {["", "Paid"].map((headerItem, index) =>
            headerItem === "Paid" ? (
              <div key={index} style={{ width: "238px" }}>
                <p className="medium14-text" style={{ marginLeft: "10px" }}>
                  To all
                </p>
                <MarkInvoiceAsPaid
                  invoice={invoice}
                  requestFunction={requestFunction}
                />
              </div>
            ) : (
              <div key={index}> </div>
            ),
          )}
        </div>
      );
    }
  };

  const grouppedLocationsNames = useMemo(() => {
    if (data?.entities) {
      return data.entities.reduce(
        (acc, item) => {
          const monthKey = moment(item.month).format("MM.YYYY").toString();
          const invoices =
            item.companyInvoices?.map((invoice, index) => [
              index + 1,
              formatedCompanyName(invoice?.company),
              invoice.company.companyEmail,
              invoice.invoice.number,
              `${formatPrice(invoice.invoice.monthSummary)}${
                invoice.invoice.monthSummary !== 0 ? "₾" : ""
              }${
                invoice.company.additionalCorrectionInNextMonthInvoice !== 0
                  ? ` (${invoice.company.additionalCorrectionInNextMonthInvoice}%)`
                  : ""
              }`,
              <MarkInvoiceAsPaid
                invoice={invoice.invoice}
                companyId={invoice.company.companyId}
                requestFunction={requestFunction}
              />,
              <InvoiceSentIcon sent={invoice.invoice.sent} />,
              <DownloadInvoice
                month={invoice.invoice?.month}
                companyId={invoice.company.companyId}
              />,
            ]) ?? [];

          return {
            ...acc,
            [monthKey]: invoices,
          };
        },
        {} as Record<string, React.ReactNode[]>,
      );
    }

    return {};
  }, [data?.entities]);

  return (
    <>
      <Typography variant="h3" mb={1.6}>
        {title}
        {isLoading && (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        )}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        Total months {data?.total}
      </Typography>
      <GroupsTable
        tableData={tableData}
        groups={grouppedLocationsNames}
        getGroupsAction={getGroupsAction}
        componentCollumnsMinWidth="90px"
      />
    </>
  );
};

export default BusinessInvoicesByMonthProviders;
