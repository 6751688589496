import { Box, CircularProgress, Typography } from "@mui/material";
import MyTable from "components/MyTable";
import useAsync from "hooks/useAsync";
import { useEffect } from "react";
import {
  PagedListOfDiscount,
  PagedListOfDiscountProgramBrand,
  PagedListOfDiscountProgramBrandLocation,
} from "services/borbalo-main.service";
import { useParams } from "react-router-dom";
import { adminDiscountProgramService } from "services/adminDiscountProgram";
import BrandState from "pages/ProvidersBrands/BrandState";
import DiscountRulesState from "pages/ProvidersBrands/DiscountRulesState";
import DiscountRequestChangesState from "pages/ProvidersBrands/DiscountRequestChangesState";
import LocationsState from "pages/ProvidersBrands/LocationsState";
import { usePreviewLanguage } from "pages/ProvidersBrands/usePreviewLanguage";

const ProvidersBrands = ({ title }: { title: string }) => {
  const { previewLanguage, SelectComponent, isEn } = usePreviewLanguage();
  const { id } = useParams();
  const { data, isLoading, run } = useAsync<
    PagedListOfDiscountProgramBrand,
    any
  >(false, previewLanguage);
  const discountsAsync = useAsync<PagedListOfDiscount, any>();
  const discountsRequestChangesAsync = useAsync<PagedListOfDiscount, any>();
  const locationsAsync = useAsync<
    PagedListOfDiscountProgramBrandLocation,
    any
  >();

  const getAllBrands = () => {
    if (id) {
      run(adminDiscountProgramService.getBrandsOfProvider(id));
    }
  };

  const getAllDiscounts = () => {
    if (id) {
      discountsAsync.run(
        adminDiscountProgramService.getDiscountsOfProvider(id),
      );
    }
  };

  const getAllLocations = () => {
    if (id) {
      locationsAsync.run(
        adminDiscountProgramService.getLocationsOfProvider(id),
      );
    }
  };

  const getAllDiscountsRequestChanges = () => {
    if (id) {
      discountsRequestChangesAsync.run(
        adminDiscountProgramService.getDiscountsWithChangeRequest(),
      );
    }
  };

  useEffect(() => {
    getAllLocations();
    getAllBrands();
    getAllDiscounts();
    getAllDiscountsRequestChanges();
  }, [id, previewLanguage]);

  const allBrands = data?.entities ?? [];

  const originalIds = allBrands
    .map(item => item.originalBrandId ?? "")
    .filter(item => item);
  const brandsToShow = allBrands.filter(({ id }) => !originalIds.includes(id));

  const locations = locationsAsync.data?.entities ?? [];
  const discounts = discountsAsync.data?.entities ?? [];
  const discountsRequestChanges =
    discountsRequestChangesAsync.data?.entities ?? [];

  const dataInfo =
    brandsToShow?.map(item => {
      return [
        isEn ? item.enTitle : item.geTitle,
        isEn ? item.enDescription : item.geDescription,
        item.categoryName,
        item.subCategory.name,
        <BrandState
          item={item}
          originalBrand={allBrands.find(
            brand => brand.id === item.originalBrandId,
          )}
          getAllBrands={getAllBrands}
          locations={locations}
          language={previewLanguage}
        />,
        <DiscountRulesState
          brand={item}
          discounts={discounts}
          getAllDiscounts={getAllDiscounts}
          isEn={isEn}
        />,
        <DiscountRequestChangesState
          brand={item}
          changeRequestDiscounts={discountsRequestChanges}
          discounts={discounts}
          getAllDiscounts={getAllDiscountsRequestChanges}
          language={previewLanguage}
        />,
        <LocationsState
          brand={item}
          locations={locations}
          getAllLocations={getAllLocations}
        />,
      ];
    }) ?? [];

  const tableData = [
    [
      "Name",
      "Description",
      "Category",
      "Sub-Category",
      "Brand State",
      "Discount Rules State",
      "Discount Changes",
      "Locations",
    ],
    ...dataInfo,
  ];

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h3" mb={1.6}>
          {title}
          {isLoading && (
            <CircularProgress
              sx={{
                alignSelf: "center",
                marginLeft: 3.6,
              }}
            />
          )}
        </Typography>
        {SelectComponent}
      </Box>
      <Typography variant="h4" mb={1.6}>
        Total {brandsToShow?.length}
      </Typography>
      <div className="column"></div>
      <MyTable tableData={tableData} />
    </>
  );
};

export default ProvidersBrands;
