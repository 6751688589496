import { CircularProgress, Typography } from "@mui/material";
import MyTable from "components/MyTable";
import useAsync from "hooks/useAsync";
import RenameLocationModal from "./RenameLocationModal";
import { useEffect, useMemo, useState } from "react";
import {
  PagedListOfRenamedLocationGroup,
  RecoverMergedRecognizedCamerasRequest,
  RenameMergedRecognizedCamerasGroupRequest,
} from "services/borbalo-main.service";
import { adminFinesService } from "services/fines";

const RenamedLocations = ({ title }: { title: string }) => {
  const { data, isLoading, run } = useAsync<
    PagedListOfRenamedLocationGroup,
    any
  >();
  const [isOpen, setIsOpen] = useState<string | undefined>(undefined);

  const openModal = (location: string) => {
    setIsOpen(location);
  };

  const closeModal = () => {
    setIsOpen(undefined);
  };

  const renameMergedRecognizedCamerasGroupAsync = useAsync<void, any>();

  const handleRenameClick = (newLocation: string) => {
    if (renameMergedRecognizedCamerasGroupAsync.isLoading) {
      return;
    }

    const entity = data?.entities?.find(
      item => item.locationFromImage === isOpen,
    );
    if (entity) {
      renameMergedRecognizedCamerasGroupAsync
        .run(
          adminFinesService.renameMergedRecognizedCamerasGroup(
            new RenameMergedRecognizedCamerasGroupRequest({
              region: entity.region,
              municipality: entity.municipality,
              locationFromImage: entity.locationFromImage,
              newLocation: newLocation,
            }),
          ),
        )
        .then(() => {
          closeModal();
          run(adminFinesService.getRenamedLocations());
        });
    }
  };

  const recoverLocationAsync = useAsync<void, any>();

  const onPress = (index: number) => {
    if (isLoading || recoverLocationAsync.isLoading) {
      return;
    }

    const entity = sortedData[index];
    if (entity) {
      recoverLocationAsync
        .run(
          adminFinesService.recoverMergedRecognizedCameras(
            new RecoverMergedRecognizedCamerasRequest({
              region: entity.region,
              municipality: entity.municipality,
              locationFromImage: entity.locationFromImage,
              initialLocationFromImage: entity.initialLocationFromImage,
            }),
          ),
        )
        .then(() => {
          run(adminFinesService.getRenamedLocations());
        });
    }
  };

  useEffect(() => {
    run(adminFinesService.getRenamedLocations());
  }, []);

  const sortedData = useMemo(() => {
    if (data?.entities) {
      return data.entities.slice().sort((a, b) => {
        return a.locationFromImage > b.locationFromImage ? 1 : -1;
      });
    }

    return [];
  }, [data?.entities]);

  const dataInfo =
    sortedData?.map(item => [
      item.finesCount.toString(),
      item.region,
      item.municipality,
      item.initialLocationFromImage,
      item.locationFromImage,
    ]) ?? [];

  const grouppedLocationsNames = useMemo(() => {
    if (sortedData) {
      return sortedData.reduce(
        (acc, item, index) =>
          acc?.[item.locationFromImage] !== undefined
            ? {
                ...acc,
                [item.locationFromImage]: {
                  ...acc[item.locationFromImage],
                  count: acc[item.locationFromImage].count + 1,
                },
              }
            : {
                ...acc,
                [item.locationFromImage]: {
                  index,
                  count: 1,
                  onPress: () => openModal(item.locationFromImage),
                },
              },
        {} as {
          [key: string]: { index: number; count: number; onPress: () => void };
        },
      );
    }

    return {};
  }, [sortedData]);

  const tableData = [
    [
      "Fines Count",
      "Region",
      "Municipality",
      "Initial Location name",
      "Current location name",
    ],
    ...dataInfo,
  ];

  return (
    <>
      <Typography variant="h3" mb={1.6}>
        {title}
        {(isLoading || recoverLocationAsync.isLoading) && (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        )}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        Total {data?.total}
      </Typography>
      <div className="column"></div>
      <MyTable
        tableData={tableData}
        onPress={onPress}
        buttonText="Recover Camera"
        isButtonDisabled={recoverLocationAsync.isLoading}
        groups={grouppedLocationsNames}
      />
      <RenameLocationModal
        isOpen={isOpen}
        toggleModal={closeModal}
        isButtonDisabled={renameMergedRecognizedCamerasGroupAsync.isLoading}
        handleRenameClick={handleRenameClick}
      />
    </>
  );
};

export default RenamedLocations;
