export const formatPrice = (
  price?: number,
  { saveZeros }: { saveZeros?: boolean } = {},
) => {
  if (!price) {
    return "0";
  }
  if (saveZeros) {
    return (Number(price) / 100)
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  return (Number(price) / 100)
    .toFixed(2)
    .replace(".", ",")
    .replace(",00", "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
