import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const InvoiceSentIcon = ({ sent }: { sent: boolean }) => {
  return (
    <>
      {sent ? (
        <DoneIcon
          style={{
            color: "green",
          }}
        />
      ) : (
        <CloseIcon
          style={{
            color: "red",
          }}
        />
      )}
    </>
  );
};

export default InvoiceSentIcon;
