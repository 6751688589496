import { Box } from "@mui/material";
import { IconSquare } from "./assets/IconSquare";

export const generalStates = {
  allActive: "allActive",
  withoutState: "withoutState",
  haveWithModeration: "haveWithModeration",
  noActiveEntries: "noActiveEntries",
};

const colorByState = {
  [generalStates.allActive]: {
    color: "main",
    text: "All are active",
  },
  [generalStates.noActiveEntries]: {
    color: "grey",
    text: "No active entries",
  },
  [generalStates.withoutState]: {
    color: "grey",
    text: "No entries",
  },
  [generalStates.haveWithModeration]: {
    color: "yellow",
    text: "On moderation",
  },
};

const GeneralStateComponent = ({
  total,
  totalWithModeration,
  totalDeactivated,
  marginBottom,
  count,
}: {
  total: number;
  totalWithModeration: number;
  totalDeactivated: number;
  marginBottom?: number;
  count?: number;
}) => {
  const getState = () => {
    if (totalWithModeration) {
      return generalStates.haveWithModeration;
    }

    if (total) {
      return generalStates.allActive;
    }

    if (totalDeactivated) {
      return generalStates.noActiveEntries;
    }

    return generalStates.withoutState;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        minWidth: 150,
        marginBottom,
      }}
    >
      <IconSquare
        className={`color-${colorByState[getState()].color} shrink0`}
        size={20}
      />
      &nbsp;&nbsp;
      {colorByState[getState()].text}
      {!!count && ` (${count})`}
    </Box>
  );
};

export default GeneralStateComponent;
