import clsx from "clsx";
import styles from "./Discount.module.scss";
import {
  DiscountChangeRequest,
  DiscountType,
  IDiscountProgramProductInfo,
} from "services/borbalo-main.service";
import { PreviewLanguage } from "store/locations/slice";

const DiscountRequestChangesPreview = ({
  data,
  language,
}: {
  data: DiscountChangeRequest;
  language: PreviewLanguage;
}) => {
  const isEn = language === "en";

  const getTextForDiscount = (amount: Partial<IDiscountProgramProductInfo>) => {
    switch (amount.discountType) {
      case DiscountType.OnePlusOne:
        return (
          <div>
            {amount?.timeLimitation && (
              <p className="regular19-text color-grey">
                {amount.timeLimitation.startTime?.slice(0, -3)} -{" "}
                {amount.timeLimitation.endTime?.slice(0, -3)}
              </p>
            )}
            {isEn ? amount.productNameEn : amount.productNameGe}
          </div>
        );
      case DiscountType.FreeProduct:
        return (
          <div>
            {amount?.timeLimitation && (
              <p className="regular19-text color-grey">
                {amount.timeLimitation.startTime?.slice(0, -3)} -{" "}
                {amount.timeLimitation.endTime?.slice(0, -3)}
              </p>
            )}
            {isEn ? amount.productNameEn : amount.productNameGe}
          </div>
        );
      case DiscountType.PercentageUpTo:
        return (
          <>
            <div style={{ alignContent: "center" }}>
              {isEn ? amount.productNameEn : amount.productNameGe}
            </div>
            <div style={{ textAlign: "end" }}>
              {amount?.timeLimitation && (
                <p className="regular19-text color-grey">
                  {amount.timeLimitation.startTime?.slice(0, -3)} -{" "}
                  {amount.timeLimitation.endTime?.slice(0, -3)}
                </p>
              )}
              <span
                className="regular19-text color-grey"
                style={{ verticalAlign: "text-top" }}
              >
                Up to{" "}
              </span>
              {`${amount.discountAmount}%`}
            </div>
          </>
        );
      default:
        return (
          <>
            <div style={{ alignContent: "center" }}>
              {isEn ? amount.productNameEn : amount.productNameGe}
            </div>
            <div style={{ textAlign: "end" }}>
              {amount?.timeLimitation && (
                <p className="regular19-text color-grey">
                  {amount.timeLimitation.startTime?.slice(0, -3)} -{" "}
                  {amount.timeLimitation.endTime?.slice(0, -3)}
                </p>
              )}
              {`-${amount.discountAmount}%`}
            </div>
          </>
        );
    }
  };

  return (
    <div className={clsx(styles["discount-info"], "row", "gap32")}>
      <div
        className={clsx(styles["discount-section"], "column", "flex1", "mw600")}
      >
        <div className={clsx(styles["discount-active"])}>
          <div className={clsx(styles["discount-active-title"], "mb8")}>
            {"Active Discount"}
          </div>

          <div
            className={clsx(
              styles["discount-active-value"],
              styles["discount-text"],
            )}
          >
            {data?.mainAmount ? `-${data.mainAmount}%` : ""}
          </div>
        </div>
        <div
          className={clsx(styles["discount-category-wrapper"], "medium24-text")}
        >
          {data?.discountAmounts?.map(amount => (
            <div
              key={amount.productNameEn}
              className={clsx(styles["discount-category"], "row-space-between")}
            >
              {getTextForDiscount(amount)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DiscountRequestChangesPreview;
