import { Box, Button, CircularProgress, Typography } from "@mui/material";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import {
  Discount,
  DiscountChangeRequest,
  DiscountProgramBrand,
  DiscountProgramState,
} from "services/borbalo-main.service";
import { adminDiscountProgramService } from "services/adminDiscountProgram";
import StateComponent from "components/StateComponent";
import PreviewModal from "pages/ProvidersBrands/PreviewModal";
import DiscountRequestChangesPreview from "pages/ProvidersBrands/Previews/DiscountRequestChangesPreview";
import { PreviewLanguage } from "store/locations/slice";

const DiscountRequestChangesState = ({
  brand,
  changeRequestDiscounts,
  discounts,
  getAllDiscounts,
  language,
}: {
  brand: DiscountProgramBrand;
  changeRequestDiscounts: Discount[];
  discounts: Discount[];
  getAllDiscounts: () => void;
  language: PreviewLanguage;
}) => {
  const changeStateAsync = useAsync<void, any>();
  const [loadingId, setLoadingId] = useState<string | undefined>();
  const [isOpenDiscountState, setIsOpenDiscountState] = useState<
    boolean | undefined
  >(false);

  const discountToShow = discounts.find(
    item =>
      (item.brandId === brand.id || item.brandId === brand.originalBrandId) &&
      !item.originalDiscountId,
  );
  const currentChangeRequestDiscount = changeRequestDiscounts.find(
    item => item.brandId === brand.id || item.brandId === brand.originalBrandId,
  );

  const currentDiscount = currentChangeRequestDiscount || discountToShow;

  const activeChanges = new DiscountChangeRequest({
    mainAmount: currentDiscount?.mainAmount,
    discountAmounts: currentDiscount?.discountAmounts ?? [],
  });
  const currentChanges =
    currentChangeRequestDiscount?.changeRequest || activeChanges;

  const stateChange = (brandId: string) => {
    if (currentChangeRequestDiscount) {
      setLoadingId(brandId);
      changeStateAsync.run(
        adminDiscountProgramService.applyDiscountChanges(
          currentChangeRequestDiscount.id,
        ),
      );
    }
  };

  useEffect(() => {
    if (changeStateAsync.isSuccess) {
      setLoadingId(undefined);
      setIsOpenDiscountState(undefined);
      getAllDiscounts();
    }
  }, [changeStateAsync.isSuccess]);

  useEffect(() => {
    if (changeStateAsync.isError) {
      setLoadingId(undefined);
    }
  }, [changeStateAsync.isError]);

  if (!currentDiscount) {
    return <>-</>;
  }

  const buttons = (
    <>
      <Button
        disabled={
          !currentChangeRequestDiscount?.changeRequest ||
          loadingId === currentDiscount.id
        }
        onClick={() => stateChange(currentDiscount.id)}
      >
        Apply Changes
        <CircularProgress
          size={10}
          sx={{
            alignSelf: "center",
            ml: 1.6,
            opacity: Number(loadingId === currentDiscount.id),
          }}
        />
      </Button>
    </>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <StateComponent
        state={
          currentChangeRequestDiscount?.changeRequest
            ? DiscountProgramState.OnModeration
            : DiscountProgramState.Active
        }
        marginBottom={1}
      />
      {buttons}
      <Button onClick={() => setIsOpenDiscountState(true)}>Show Preview</Button>
      <PreviewModal
        isOpen={!!isOpenDiscountState}
        toggleModal={() => setIsOpenDiscountState(undefined)}
        buttons={buttons}
      >
        {isOpenDiscountState && (
          <>
            {currentChangeRequestDiscount?.changeRequest && (
              <div className="column-align-center">
                <Typography variant="h6" sx={{ color: "#00c6be", mb: 1 }}>
                  Active State
                </Typography>
                <DiscountRequestChangesPreview
                  data={activeChanges}
                  language={language}
                />
              </div>
            )}
            <div className="column-align-center">
              {currentChangeRequestDiscount && (
                <Typography variant="h6" sx={{ color: "#f9b60a", mb: 1 }}>
                  On Moderation
                </Typography>
              )}
              <DiscountRequestChangesPreview
                data={currentChanges}
                language={language}
              />
            </div>
          </>
        )}
      </PreviewModal>
    </Box>
  );
};

export default DiscountRequestChangesState;
